import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, Upload, message, Typography, Table, Modal, Divider, Space, Switch } from 'antd';
import { UploadOutlined, EditOutlined, DeleteOutlined, PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import axios from 'axios';

const { Option } = Select;
const { Title } = Typography;

const ServicePage = () =>
{
    const [form] = Form.useForm();
    const [locations, setLocations] = useState([]);
    const [pages, setPages] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editingPage, setEditingPage] = useState(null);

    const normFile = (e) =>
    {
        if (Array.isArray(e))
        {
            return e;
        }
        return e && e.fileList;
    };
    useEffect(() =>
    {
        const fetchLocations = async () =>
        {
            try
            {
                const response = await axios.get('https://api.ridgetechcorp.com/api/locations');
                setLocations(response.data);
            } catch (error)
            {
                message.error('Failed to load locations');
            }
        };
        fetchLocations();
        fetchPages();
    }, []);

    const fetchPages = async () =>
    {
        try
        {
            const response = await axios.get('https://api.ridgetechcorp.com/api/page');
            setPages(response.data);
        } catch (error)
        {
            message.error('Failed to load pages');
        }
    };

    const handleUpload = async (file) =>
    {
        const formData = new FormData();
        formData.append('image', file);

        try
        {
            const response = await axios.post('https://api.ridgetechcorp.com/api/upload', formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
            return response.data.imageUrl;
        } catch (error)
        {
            message.error('Upload failed');
            return null;
        }
    };

    const onFinish = async (values) =>
    {
        const transformedValues = {
            ...values,
            content: {
                ...values.content,
                heroSection: {
                    ...values.content.heroSection,
                    secondSlide: {
                        ...values.content.heroSection.secondSlide,
                        getAsolution: values.content.heroSection.secondSlide.getAsolution?.map((item) => ({
                            ...item,
                            tag: {
                                ...item.tag,
                                icon: item.tag.icon?.file?.response?.url || item.tag.icon || 'not given', // Safe access to file.response.url
                            },
                        })) || [],
                        HireATeam: values.content.heroSection.secondSlide.HireATeam?.map((item) => ({
                            ...item,
                            tag: {
                                ...item.tag,
                                icon: item.tag.icon?.file?.response?.url || item.tag.icon || 'not given', // Safe access to file.response.url
                            },
                        })) || [],
                    },
                },
                Innovation: {
                    ...values.content.Innovation,
                    image: values.content.Innovation.image?.file?.response?.url || values.content.Innovation.image || 'not given', // Safe access to image URL
                },
                whyService: {
                    ...values.content.whyService,
                    feature: values.content.whyService?.feature?.map((item) => ({
                        ...item,
                        icon: item.icon?.file?.response?.url || item.icon || 'not given', // Safe access to file.response.url
                    })) || [],
                },
                projects: {
                    ...values.content.projects,
                    feature: values.content.projects?.feature?.map((item) => ({
                        ...item,
                        icon: item.icon?.file?.response?.url || item.icon || 'not given', // Safe access to file.response.url
                    })) || [],
                },
                // Include whyUs if it exists in values.content
                whyUs: values.content.whyUs || {},
            },
        };

        console.log({ transformedValues });


        try
        {
            if (editingPage)
            {
                await axios.put(`https://api.ridgetechcorp.com/api/page/${ editingPage._id }`, transformedValues);
                message.success('Page updated successfully');
            } else
            {
                await axios.post('https://api.ridgetechcorp.com/api/page/create', transformedValues);
                message.success('Page created successfully');
            }
            setIsModalOpen(false);
            fetchPages();
        } catch (error)
        {
            message.error(`Failed to ${ editingPage ? 'update' : 'create' } page`);
        }
    };

    const editPage = (page) =>
    {
        setEditingPage(page);
        setIsModalOpen(true);
        console.log({ page })
        form.setFieldsValue(page);
        form.setFieldValue('locations', [page.locations[0]._id])

    };

    const deletePage = async (_id) =>
    {
        console.log(_id,"this is the id")
        try
        {
           const res= await axios.delete(`https://api.ridgetechcorp.com/api/page/${_id}`);
           console.log(res,_id,"this is the ressssssss");
            message.success('Page deleted successfully');
            fetchPages();
        } catch (error)
        {
            message.error('Failed to delete page');
        }
    };

    const resetForm = () =>
    {
        form.resetFields();
        setEditingPage(null);
    };
    console.log(form)
    const updatePageStatus = async (pageId, currentStatus, setPages) =>
    {
        try
        {
            // Call API to toggle the status
            const response = await axios.post(`https://api.ridgetechcorp.com/api/page/changeStatus`,
                { pageId },
            );

            if (response.data)
            {
                fetchPages()
                message.success(`Page status updated `);
            } else
            {
                throw new Error('Failed to update page status');
            }
        } catch (error)
        {
            console.error('Error updating status:', error);
            message.error('Error updating page status');
        }
    };


    return (
        <div>
            <Title level={3}>Manage Service Pages</Title>
            <Button type="primary" onClick={() => setIsModalOpen(true)} style={{ marginBottom: 16 }}>
                Create New Page
            </Button>
            <Table
                dataSource={pages}
                rowKey="slug"
                columns={[
                    { title: 'Page Name', dataIndex: 'name', key: 'name' },
                    {
                        title: 'Location', dataIndex: 'locations', key: 'locations',
                        render: (_, record) => (
                            <>
                                {console.log({ record })}
                                <p>{record.locations[0]?.country}</p>
                            </>
                        ),
                    },
                    {
                        title: 'status', dataIndex: 'status', key: 'status',
                        render: (_, record) => (
                            <>
                                {console.log({ record })}
                                <Switch
                                    checked={record.status === 'active'}
                                    onChange={() => updatePageStatus(record._id, record.status, setPages)}
                                    checkedChildren="Active"
                                    unCheckedChildren="Inactive"
                                />

                            </>
                        ),
                    },
                    {
                        title: 'Actions',
                        key: 'actions',
                        render: (_, record) => (
                            <>
                            {console.log(record,"this is the record")}

                                <Button icon={<EditOutlined />} onClick={() => editPage(record)} />
                                <Button icon={<DeleteOutlined />} onClick={() => deletePage(record?._id)} danger />
                            </>
                        ),
                    },
                ]}
            />

            <Modal
                title={editingPage ? 'Edit Page' : 'Create Page'}
                open={isModalOpen}
                onCancel={() => setIsModalOpen(false)}
                footer={null}
                afterClose={resetForm}
            >
                <Form layout="vertical" onFinish={onFinish} form={form}>
                    {/* Basic Page Information */}
                    <Form.Item label="Page Name" name="name" rules={[{ required: true, message: 'Please input the page name!' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="Meta Title" name="metaTitle" rules={[{ required: true, message: 'Please input the page name!' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="Meta Description" name="metaDescription" rules={[{ required: true, message: 'Please input the page name!' }]}>
                        <Input />
                    </Form.Item>


                    <Form.Item label="Location" name="locations" rules={[{ required: true, message: 'Please select a location!' }]}>
                        <Select mode="multiple" placeholder="Select a location">
                            {locations.map((loc) => (
                                <Option key={loc._id} value={loc._id}>{loc?.country}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item label="CTA Button Text" name="CTA_text" rules={[{ required: true, message: 'Please select a location!' }]}>
                        <Input placeholder='CTA_text' />
                    </Form.Item>

                    {/* Hero Section - First Slide */}
                    <Divider>Hero Section - First Slide</Divider>
                    <Form.Item label="Heading" name={['content', 'heroSection', 'firstSlide', 'heading']}>
                        <Input placeholder="Enter heading" />
                    </Form.Item>
                    <Form.Item label="Sub-heading" name={['content', 'heroSection', 'firstSlide', 'sub_heading']}>
                        <Input placeholder="Enter sub-heading" />
                    </Form.Item>

                    {/* Hero Section - Second Slide */}
                    <Divider>Hero Section - Second Slide</Divider>
                    <Form.Item label="Heading" name={['content', 'heroSection', 'secondSlide', 'heading']}>
                        <Input placeholder="Enter heading" />
                    </Form.Item>
                    <Form.Item label="Sub-heading" name={['content', 'heroSection', 'secondSlide', 'sub_heading']}>
                        <Input placeholder="Enter sub-heading" />
                    </Form.Item>

                    {/* Get A Solution Tags */}
                    <Divider>Get a Solution</Divider>
                    <Form.List name={['content', 'heroSection', 'secondSlide', 'getAsolution']}>
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, ...restField }) => (
                                    <Space key={key} align="baseline">
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'tag', 'icon']}
                                            label="Icon"
                                            normalize={(value) => (value?.response?.url || value)} // Extract URL if present
                                        >
                                            <Upload
                                                customRequest={async ({ file, onSuccess }) =>
                                                {
                                                    const url = await handleUpload(file);
                                                    if (url)
                                                    {
                                                        console.log("Uploaded URL:", url);
                                                        file.response = { url }; // Manually set the response URL
                                                        onSuccess({ url });
                                                        form.setFieldsValue({
                                                            [`content.heroSection.secondSlide.getAsolution[${ name }].tag.icon`]: url,
                                                        });
                                                    }
                                                }}
                                                showUploadList={true}
                                                onChange={({ file }) =>
                                                {
                                                    if (file.status === 'done' && file.response)
                                                    {
                                                        console.log(file.response.url)
                                                        // Update only with the URL
                                                        form.setFieldsValue({
                                                            [`content.heroSection.secondSlide.getAsolution[${ name }].tag.icon`]: file.response.url,
                                                        });
                                                    }
                                                }}
                                            >
                                                <Button icon={<UploadOutlined />}>Upload Icon</Button>
                                            </Upload>
                                        </Form.Item>

                                        <Form.Item {...restField} name={[name, 'tag', 'label']} label="Label">
                                            <Input placeholder="Enter label" />
                                        </Form.Item>
                                        <MinusCircleOutlined onClick={() => remove(name)} />
                                    </Space>
                                ))}
                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    Add Get a Solution Tag
                                </Button>
                            </>
                        )}
                    </Form.List>




                    <Divider>Hire a Team</Divider>
                    <Form.List name={['content', 'heroSection', 'secondSlide', 'HireATeam']}>
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, ...restField }) => (
                                    <Space key={key} align="baseline">
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'tag', 'icon']}
                                            label="Icon"
                                            normalize={(value) => (value?.response?.url || value)} // Extract URL if present
                                        >
                                            <Upload
                                                customRequest={async ({ file, onSuccess }) =>
                                                {
                                                    const url = await handleUpload(file);
                                                    if (url)
                                                    {
                                                        file.response = { url };
                                                        form.setFieldValue([name, 'tag', 'icon'], url);
                                                        onSuccess({ url });
                                                    }
                                                }}
                                                showUploadList={true}
                                                onChange={({ file }) =>
                                                {
                                                    if (file.status === 'done' && file.response)
                                                    {
                                                        console.log(file.response.url)
                                                        // Update only with the URL

                                                    }
                                                }}
                                            >
                                                <Button icon={<UploadOutlined />}>Upload Icon</Button>
                                            </Upload>
                                        </Form.Item>
                                        <Form.Item {...restField} name={[name, 'tag', 'label']} label="Label">
                                            <Input placeholder="Enter label" />
                                        </Form.Item>
                                        <MinusCircleOutlined onClick={() => remove(name)} />
                                    </Space>
                                ))}
                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    Add Hire Team Tag
                                </Button>
                            </>
                        )}
                    </Form.List>
                    {/* Expertise Section */}
                    <Divider>Expertise Section</Divider>
                    <Form.Item label="Expertise Heading" name={['content', 'expertise', 'heading']}>
                        <Input placeholder="Enter expertise heading" />
                    </Form.Item>
                    <Form.Item label="Expertise Sub-description" name={['content', 'expertise', 'sub_desc']}>
                        <Input placeholder="Enter expertise sub-description" />
                    </Form.Item>

                    {/* Expertise Content - Array */}
                    <Form.List name={['content', 'expertise', 'expertise_content']}>
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, ...restField }) => (
                                    <Space key={key} align="baseline">
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'heading']}
                                            label="Content Heading"
                                            rules={[{ required: true, message: 'Please enter content heading' }]}
                                        >
                                            <Input placeholder="Enter content heading" />
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'sub_desc']}
                                            label="Content Sub-description"
                                            rules={[{ required: true, message: 'Please enter content sub-description' }]}
                                        >
                                            <Input placeholder="Enter content sub-description" />
                                        </Form.Item>
                                        <MinusCircleOutlined onClick={() => remove(name)} />
                                    </Space>
                                ))}
                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    Add Expertise Content
                                </Button>
                            </>
                        )}
                    </Form.List>
                    {/* Image Banner Section */}
                    <Divider>Image Banner Section</Divider>
                    <Form.Item label="Image Banner Heading" name={['content', 'image_banner', 'heading']}>
                        <Input placeholder="Enter image banner heading" />
                    </Form.Item>
                    <Form.Item label="Image Banner Sub-description" name={['content', 'image_banner', 'sub_desc']}>
                        <Input placeholder="Enter image banner sub-description" />
                    </Form.Item>

                    {/* Image Banner Feature - Array */}
                    <Form.List name={['content', 'image_banner', 'feature']}>
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, ...restField }) => (
                                    <Space key={key} align="baseline">
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'text']}
                                            label="Feature Text"
                                            rules={[{ required: true, message: 'Please enter feature text' }]}
                                        >
                                            <Input placeholder="Enter feature text" />
                                        </Form.Item>
                                        <MinusCircleOutlined onClick={() => remove(name)} />
                                    </Space>
                                ))}
                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    Add Feature
                                </Button>
                            </>
                        )}
                    </Form.List>

                    {/* Innovation Section */}
                    <Divider>Innovation</Divider>
                    <Form.Item label="Innovation Heading" name={['content', 'Innovation', 'heading']}>
                        <Input placeholder="Enter innovation heading" />
                    </Form.Item>
                    <Form.Item label="Innovation Sub-description" name={['content', 'Innovation', 'sub_desc']}>
                        <Input placeholder="Enter innovation sub-description" />
                    </Form.Item>
                    <Form.Item
                        label="Innovation Image"
                        name={['content', 'Innovation', 'image']}
                        normalize={(value) => (value?.response?.url || value)} // Extract URL if present
                    >
                        <Upload
                            name="image"
                            listType="picture"
                            accept="image/*"
                            customRequest={async ({ file, onSuccess }) =>
                            {
                                const url = await handleUpload(file);
                                if (url)
                                {
                                    file.response = { url };
                                    // form.setFieldValue([name, 'tag', 'icon'], url);
                                    form.setFieldsValue({
                                        content: {
                                            ...form.getFieldValue('content'),
                                            Innovation: { ...form.getFieldValue(['content', 'Innovation']), image: [{ url: url }] },
                                        },
                                    });

                                    onSuccess({ url });
                                }
                            }}
                        >
                            <Button icon={<UploadOutlined />}>Click to Upload</Button>
                        </Upload>
                    </Form.Item>


                    {/* Projects Section */}
                    <Divider>Projects</Divider>
                    <Form.Item label="Projects Heading" name={['content', 'projects', 'heading']}>
                        <Input placeholder="Enter projects heading" />
                    </Form.Item>
                    <Form.Item label="Projects Sub-description" name={['content', 'projects', 'sub_desc']}>
                        <Input placeholder="Enter projects sub-description" />
                    </Form.Item>
                    <Form.List name={['content', 'projects', 'feature']}>
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, ...restField }) => (
                                    <Space key={key} align="baseline">
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'heading']}
                                            label="Heading"
                                            rules={[{ required: true, message: 'Please enter feature text' }]}
                                        >
                                            <Input placeholder="Enter heading text" />
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'para']}
                                            label="Paragrah"
                                            rules={[{ required: true, message: 'Please enter feature text' }]}
                                        >
                                            <Input placeholder="Enter para text" />

                                        </Form.Item>
                                        <Form.Item label="Feature Icon" name={[name, 'icon']}>
                                            <Upload
                                                name="icon"
                                                action='https://api.ridgetechcorp.com/api/upload'
                                                listType="picture"
                                                customRequest={async ({ file, onSuccess }) =>
                                                {
                                                    const url = await handleUpload(file);
                                                    if (url)
                                                    {
                                                        file.response = { url };
                                                        // form.setFieldValue([name, 'tag', 'icon'], url);

                                                        onSuccess({ url });
                                                    }
                                                }}
                                            >
                                                <Button icon={<UploadOutlined />}>Upload Icon</Button>
                                            </Upload>
                                        </Form.Item>
                                        <Button type="link" onClick={() => remove(name)}>Remove Feature</Button>

                                    </Space>

                                ))}

                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    Add More
                                </Button>
                            </>
                        )}
                    </Form.List>

                    {/* Why Service Section */}
                    <Divider>Why Service</Divider>
                    <Form.Item label="Why Service Heading" name={['content', 'whyService', 'heading']}>
                        <Input placeholder="Enter why service heading" />
                    </Form.Item>
                    <Form.Item label="Why Service Sub-description" name={['content', 'whyService', 'sub_desc']}>
                        <Input placeholder="Enter why service sub-description" />
                    </Form.Item>
                    {/* Why Service Feature List with Icon Upload */}
                    <Form.List name={['content', 'whyService', 'feature']}>
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, ...restField }) => (
                                    <div key={key} style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'heading']}
                                            label="Feature Heading"
                                        >
                                            <Input placeholder="Enter feature heading" />
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'para']}
                                            label="Feature Description"
                                        >
                                            <Input placeholder="Enter feature description" />
                                        </Form.Item>

                                        {/* Icon Upload */}
                                        <Form.Item label="Feature Icon" name={[name, 'icon']}>
                                            <Upload
                                                name="image"
                                                action='https://api.ridgetechcorp.com/api/upload'
                                                listType="picture"
                                                customRequest={async ({ file, onSuccess }) =>
                                                {
                                                    const url = await handleUpload(file);
                                                    if (url)
                                                    {
                                                        file.response = { url };
                                                        // form.setFieldValue([name, 'tag', 'icon'], url);

                                                        onSuccess({ url });
                                                    }
                                                }}
                                            >
                                                <Button icon={<UploadOutlined />}>Upload Icon</Button>
                                            </Upload>
                                        </Form.Item>

                                        <Button type="danger" onClick={() => remove(name)}>
                                            Remove Feature
                                        </Button>
                                    </div>
                                ))}
                                <Form.Item>
                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                        Add Feature
                                    </Button>
                                </Form.Item>
                            </>
                        )}

                    </Form.List>

                    <Divider>Why Us</Divider>
                    <Form.Item label="Why Us Heading" name={['content', 'whyUs', 'heading']}>
                        <Input placeholder="Enter why us heading" />
                    </Form.Item>
                    <Form.Item label="Why Us Sub-description" name={['content', 'whyUs', 'sub_desc']}>
                        <Input placeholder="Enter why us sub-description" />
                    </Form.Item>

                    {/* Why Us Features */}
                    <Form.List
                        name={['content', 'whyUs', 'feature']}
                        initialValue={['']}
                        rules={[
                            {
                                validator: async (_, names) =>
                                {
                                    if (!names || names.length < 1)
                                    {
                                        return Promise.reject(new Error('At least one feature'));
                                    }
                                },
                            },
                        ]}
                    >
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, fieldKey, field }) => (
                                    <div key={key} style={{ marginBottom: 10 }}>
                                        <Form.Item
                                            label={`Feature Heading`}
                                            name={[name, 'heading']}
                                            fieldKey={[fieldKey, 'heading']}
                                            rules={[{ required: true, message: 'Feature heading is required' }]}
                                        >
                                            <Input placeholder="Enter feature heading" />
                                        </Form.Item>

                                        <Form.Item
                                            label={`Feature Description`}
                                            name={[name, 'para']}
                                            fieldKey={[fieldKey, 'para']}
                                            rules={[{ required: true, message: 'Feature description is required' }]}
                                        >
                                            <Input placeholder="Enter feature description" />
                                        </Form.Item>

                                        <Button type="link" onClick={() => remove(name)}>Remove Feature</Button>
                                    </div>
                                ))}
                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    Add Feature
                                </Button>
                            </>
                        )}
                    </Form.List>
                    {/* Submit Button */}
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            {editingPage ? 'Update Page' : 'Create Page'}
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default ServicePage;
