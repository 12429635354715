// App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import AdminLayout from './Component/AdminDash/AdminPanel'; // Import AdminLayout
import HireDeveloper from './Component/AdminDash/HireDeveloper';
import AddDeveloper from './Component/AdminDash/AddDeveloper';
import BlogPosting from './Component/AdminDash/BlogPosting';
import BlogList from './Component/AdminDash/BlogList';
import Login from './Component/Login';
import ProfileDetails from './Component/AdminDash/ProfileDetails'
import UserManagement from './Component/AdminDash/UserManagement';
import TagManagement from './Component/AdminDash/TagManagement';
import ImageGallery from './Component/AdminDash/ImageGallery';
import JobPosting from './Component/AdminDash/JobPosting';
import ResourceManagement from './Component/AdminDash/ResourceManagement/ResourceManagement';
import LocationManagement from './Component/AdminDash/LocationManagement/LocationManagement';
import ParentResource from './Component/AdminDash/ParentResource';
import QueriesTable from './Component/AdminDash/QueriesTable';
import ServicePage from './Component/AdminDash/ServicePage/ServicePage';
import TechnologyManager from './Component/AdminDash/Technology/Technology';
import ServiceManager from './Component/AdminDash/Service/Service';
import FAQManagement from './Component/AdminDash/FaqManagement/FaqManagement';
import CaseStudy from './Component/AdminDash/CaseStudy/CaseStudy';
import LandingPageTable from './Component/LandingPage/LandingPage';



const App = () =>
{
    const [isAuthenticated, setIsAuthenticated] = useState(true);

    useEffect(() =>
    {
        const handleStorageChange = () =>
        {
            // setIsAuthenticated(sessionStorage.getItem('isAuthenticated') === 'true');
            setIsAuthenticated(true)
        };

        // window.addEventListener('storage', handleStorageChange);
        // return () => window.removeEventListener('storage', handleStorageChange);
    }, []);

    return (
        <Router>
            <Routes>
                <Route path="/login" element={<Login setIsAuthenticated={setIsAuthenticated} />} />

                {/* Wrap all admin routes inside AdminLayout */}
                <Route element={<AdminLayout setIsAuthenticated={true} />}>
                    <Route path="/admin" element={<Navigate to="/dashboard" />} />
                    <Route path="/dashboard" element={<ProfileDetails />} />
                    <Route path="/hire-developer" element={<HireDeveloper />} />
                    <Route path="/user-management" element={<UserManagement />} />
                    <Route path="/add-developer" element={<AddDeveloper />} />
                    <Route path="/blog-posting" element={<BlogPosting />} />
                    <Route path="/blog-list" element={<BlogList />} />
                    <Route path="/tag-management" element={<TagManagement />} />
                    <Route path="/image-gallery" element={<ImageGallery />} />
                    <Route path="/job-posting" element={<JobPosting />} />
                    <Route path="/resource-management" element={<ResourceManagement />} />
                    <Route path="/location-management" element={<LocationManagement />} />
                    <Route path="/parent-resource-management" element={<ParentResource />} />
                    <Route path="/query-table" element={<QueriesTable />} />
                    <Route path="/service-page" element={<ServicePage />} />
                    <Route path="/technology" element={<TechnologyManager />} />
                    <Route path="/service" element={<ServiceManager />} />
                    <Route path="/case-study" element={<CaseStudy />} />
                    <Route path="/landing-page" element={<LandingPageTable />} />
                    <Route path="/faq" element={<FAQManagement />} />

                </Route>

                <Route path="*" element={<Login setIsAuthenticated={setIsAuthenticated} />} />
            </Routes>
        </Router>
    );
};

export default App;
