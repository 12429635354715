import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Form, Input, message, Space, Popconfirm, Upload } from 'antd';
import axios from 'axios';

const LandingPageTable = () =>
{
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [editingRecord, setEditingRecord] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [form] = Form.useForm();

    // Fetch landing pages
    const fetchData = async () =>
    {
        setLoading(true);
        try
        {
            const response = await axios.get('https://api.ridgetechcorp.com/api/landing-pages');
            setData(response.data);
        } catch (error)
        {
            message.error('Failed to load data');
        } finally
        {
            setLoading(false);
        }
    };

    useEffect(() =>
    {
        fetchData();
    }, []);

    // Handle create/update
    const handleSubmit = async (values) =>
    {
        console.log(values)
        return false
        try
        {
            if (editingRecord)
            {
                // Update existing record
                await axios.put(`https://api.ridgetechcorp.com/api/landing-pages/${ editingRecord._id }`, values);
                message.success('Landing Page updated successfully');
            } else
            {
                // Create new record
                await axios.post('https://api.ridgetechcorp.com/api/landing-pages', values);
                message.success('Landing Page created successfully');
            }
            fetchData();
            setIsModalOpen(false);
            form.resetFields();
        } catch (error)
        {
            message.error('Failed to save data');
        }
    };

    // Handle delete
    const handleDelete = async (id) =>
    {
        try
        {
            await axios.delete(`https://api.ridgetechcorp.com/api/landing-pages/${ id }`);
            message.success('Landing Page deleted successfully');
            fetchData();
        } catch (error)
        {
            message.error('Failed to delete');
        }
    };

    // Image upload function
    const uploadImage = async (file) =>
    {
        try
        {
            const formData = new FormData();
            formData.append('image', file);

            const response = await axios.post(`https://api.ridgetechcorp.com/api/upload`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
            console.log(response);
            return response.data.imageUrl; // Assuming the API returns the image URL in `url`
        } catch (error)
        {
            message.error('Image upload failed.');
            throw error;
        }
    };

    // Columns for the table
    const columns = [
        { title: 'Title', dataIndex: 'title', key: 'title' },
        { title: 'Description', dataIndex: 'description', key: 'description' },
        { title: 'Status', dataIndex: 'status', key: 'status' },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <Space>
                    <Button type="primary" onClick={() =>
                    {
                        setEditingRecord(record);
                        setIsModalOpen(true);
                        form.setFieldsValue(record);
                    }}>
                        Edit
                    </Button>
                    <Popconfirm title="Are you sure?" onConfirm={() => handleDelete(record._id)}>
                        <Button danger>Delete</Button>
                    </Popconfirm>
                </Space>
            )
        }
    ];

    return (
        <div className="p-4">
            <div className="flex justify-between mb-4">
                <h2 className="text-2xl font-bold">Landing Pages</h2>
                <Button type="primary" onClick={() => setIsModalOpen(true)}>
                    Add Landing Page
                </Button>
            </div>
            <Table
                columns={columns}
                dataSource={data}
                loading={loading}
                rowKey="_id"
            />

            {/* Modal for Create/Update */}
            <Modal
                title={editingRecord ? 'Edit Landing Page' : 'Add Landing Page'}
                open={isModalOpen}
                onCancel={() =>
                {
                    setIsModalOpen(false);
                    form.resetFields();
                }}
                onOk={() => form.submit()}
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleSubmit}
                >
                    <Form.Item name="title" label="Title" rules={[{ required: true, message: 'Please enter the title' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="description" label="Description">
                        <Input.TextArea />
                    </Form.Item>
                    <Form.Item name="status" label="Status">
                        <Input />
                    </Form.Item>
                    <Form.Item name="image" label="Image">
                        <Upload
                            // customRequest={async ({ file, onSuccess, onError }) =>
                            // {
                            //     try
                            //     {
                            //         const url = await uploadImage(file); // Upload the image
                            //         console.log({ url })
                            //         file.response = { url };
                            //         form.setFieldsValue({ image: url }); // Update the form
                            //         onSuccess({ url }); // Notify Upload component of success
                            //     } catch (error)
                            //     {
                            //         onError(error); // Notify Upload component of failure
                            //     }
                            // }}
                            customRequest={async ({ file, onSuccess, onError }) =>
                            {
                                try
                                {
                                    const imageUrl = await uploadImage(file);
                                    console.log({ imageUrl })
                                    file.response = { imageUrl };
                                    form.setFieldsValue({ image: imageUrl });
                                    onSuccess({ imageUrl });
                                } catch (error)
                                {
                                    onError(error);
                                }
                            }}

                        >
                            <Button>Upload Image</Button>
                        </Upload>
                    </Form.Item>
                    <Form.Item name="home_cta_button.text" label="CTA Button Text">
                        <Input />
                    </Form.Item>
                    <Form.Item name="home_cta_button.link" label="CTA Button Link">
                        <Input />
                    </Form.Item>
                    <Form.Item name="metadescription" label="Meta Description">
                        <Input />
                    </Form.Item>
                    <Form.Item name="themeTitle" label="Theme Title">
                        <Input />
                    </Form.Item>
                    <Form.Item name="themeDescription" label="Theme Description">
                        <Input />
                    </Form.Item>
                    <Form.Item name="themeEmphasize" label="Theme Emphasize">
                        <Input />
                    </Form.Item>
                    <Form.Item label="Theme Images">
                        <Form.List name={['themeImage', 'row']} initialValue={editingRecord?.themeImage?.row || []}>
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name, fieldKey, ...restField }) => (
                                        <div key={key} className="theme-row">
                                            <h4>Row {key + 1}</h4>
                                            <Form.List name={[name, 'images']}>
                                                {(imageFields, { add: addImage, remove: removeImage }) => (
                                                    <>
                                                        {imageFields.map(({ key: imgKey, name: imgName, fieldKey: imgFieldKey, ...restImageField }) => (
                                                            <div key={imgKey} className="image-item">
                                                                <Form.Item
                                                                    {...restImageField}
                                                                    name={[imgName, 'url']}
                                                                    fieldKey={[imgFieldKey, 'url']}
                                                                    label="Image URL"
                                                                    rules={[{ required: true, message: 'Please upload an image' }]}
                                                                >
                                                                    <Upload
                                                                        customRequest={async ({ file, onSuccess, onError }) =>
                                                                        {
                                                                            try
                                                                            {
                                                                                const imageUrl = await uploadImage(file);
                                                                                const updatedRows = [...form.getFieldValue(['themeImage', 'row'])];
                                                                                updatedRows[name].images[imgName] = { url: imageUrl };
                                                                                form.setFieldsValue({ themeImage: { row: updatedRows } });
                                                                                onSuccess({ imageUrl });
                                                                            } catch (error)
                                                                            {
                                                                                onError(error);
                                                                            }
                                                                        }}





                                                                    >
                                                                        <Button>Upload Image</Button>
                                                                    </Upload>
                                                                </Form.Item>

                                                                <Form.Item
                                                                    {...restImageField}
                                                                    name={[imgName, 'alt']}
                                                                    fieldKey={[imgFieldKey, 'alt']}
                                                                    label="Alt Text"
                                                                >
                                                                    <Input placeholder="Optional alt text" />
                                                                </Form.Item>

                                                                <Button type="danger" onClick={() => removeImage(imgName)}>Remove Image</Button>
                                                            </div>
                                                        ))}
                                                        <Button type="dashed" onClick={() => addImage()} icon="plus">Add Image</Button>
                                                    </>
                                                )}
                                            </Form.List>
                                            <Button type="danger" onClick={() => remove(name)}>Remove Row</Button>
                                        </div>
                                    ))}
                                    <Button type="dashed" onClick={() => add()} icon="plus">Add Row</Button>
                                </>
                            )}
                        </Form.List>
                    </Form.Item>


                    <Form.Item name="theme_cta_button.text" label="Theme CTA Button Text">
                        <Input />
                    </Form.Item>
                    <Form.Item name="theme_cta_button.link" label="Theme CTA Button Link">
                        <Input />
                    </Form.Item>
                    <Form.Item name="pricingHeading" label="Pricing Heading">
                        <Input />
                    </Form.Item>
                    <Form.Item name="pricingDescription" label="Pricing Description">
                        <Input />
                    </Form.Item>
                    <Form.Item label="Plans">
                        <Form.List name="plans">
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name, fieldKey, ...restField }) => (
                                        <div key={key} className="plan-item">
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'name']}
                                                fieldKey={[fieldKey, 'name']}
                                                rules={[{ required: true, message: 'Plan name is required' }]}
                                            >
                                                <Input placeholder="Plan Name" />
                                            </Form.Item>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'price']}
                                                fieldKey={[fieldKey, 'price']}
                                                rules={[{ required: true, message: 'Price is required' }]}
                                            >
                                                <Input placeholder="Price" />
                                            </Form.Item>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'description']}
                                                fieldKey={[fieldKey, 'description']}
                                            >
                                                <Input.TextArea placeholder="Description" />
                                            </Form.Item>
                                            <Button type="danger" onClick={() => remove(name)}>
                                                Remove
                                            </Button>
                                        </div>
                                    ))}
                                    <Button type="dashed" onClick={() => add()}>
                                        Add Plan
                                    </Button>
                                </>
                            )}
                        </Form.List>
                    </Form.Item>
                    <Form.Item name="featureName" label="Feature Name">
                        <Input />
                    </Form.Item>
                    <Form.Item name="featureDescription" label="Feature Description">
                        <Input />
                    </Form.Item>
                    <Form.Item label="Features List">
                        <Form.List name="featuresList" initialValue={editingRecord?.featuresList || []}>
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name, fieldKey, ...restField }) => (
                                        <div key={key} className="feature-item">
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'heading']}
                                                fieldKey={[fieldKey, 'heading']}
                                                label="Heading"
                                                rules={[{ required: true, message: 'Heading is required' }]}
                                            >
                                                <Input placeholder="Feature Heading" />
                                            </Form.Item>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'description']}
                                                fieldKey={[fieldKey, 'description']}
                                                label="Description"
                                            >
                                                <Input.TextArea placeholder="Feature Description" />
                                            </Form.Item>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'link']}
                                                fieldKey={[fieldKey, 'link']}
                                                label="Link"
                                            >
                                                <Input placeholder="Feature Link" />
                                            </Form.Item>
                                            <Button type="danger" onClick={() => remove(name)}>Remove Feature</Button>
                                        </div>
                                    ))}
                                    <Button type="dashed" onClick={() => add()} icon="plus">Add Feature</Button>
                                </>
                            )}
                        </Form.List>
                    </Form.Item>

                    <Form.Item name="feature_cta_button.text" label="Feature CTA Button Text">
                        <Input />
                    </Form.Item>
                    <Form.Item name="feature_cta_button.link" label="Feature CTA Button Link">
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
        </div >
    );
};

export default LandingPageTable;
